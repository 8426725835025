<template>
    <modal ref="modalVentasUltimos" :titulo="tituloModal" no-aceptar no-cancelar>
        <div v-if="showFecha" class="row mx-0 px-5 mb-3">
            <div class="col px-0 text-general f-17 f-500">
                {{ formatearFecha(fecha[0]) }} - {{ formatearFecha(fecha[1]) }}
            </div>
        </div>
        <div v-for="data in datos" :key="data.id" class="row mx-0 px-4 my-2">
            <div class="col-12">
                <p class="text-general f-15">
                    {{ data.nombre }}
                </p>
                <p class="text-general f-14">
                    {{ tipoCantidad ? agregarSeparadoresNumero(data.total) : separadorNumero(data.total) }}
                </p>
                <el-popover
                placement="bottom"
                width="300"
                popper-class="p-0 br-12 px-3 py-3"
                trigger="hover"
                >
                    <p class="text-general f-12 f-600 mb-2 pl-4">
                        {{ data.nombre }}
                    </p>
                    <div v-for="rango in data.rangos " :key="rango.fecha" class="row mx-0">
                        <div :style="`width:12px;height:12px;background-color:${rango.color}`" />
                        <div class="col px-0 text-center text-general2">
                            {{ rango.texto }}
                        </div>
                        <div class="col px-0 text-center text-general2">
                            {{ tipoCantidad ? agregarSeparadoresNumero(rango.valor) : separadorNumero(rango.valor) }}
                        </div>
                    </div>
                    <div slot="reference" class="row mx-0 bg-fondo" style="height:10px;">
                        <div v-for="rango in data.rangos" :key="rango.fecha" class="h-100" :style="`background-color:${rango.color};width:${rango.porcentaje}%;`" />
                    </div>
                </el-popover>
            </div>
        </div>
    </modal>
</template>

<script>
import comparativa from "~/services/teams/comparativa"
const colors = ['#01B8AA','#374649', '#FD625E', '#F2C80F', '#5F6B6D', '#8AD4EB', '#FE9666', '#3599B8', '#72D4CC', '#A66999', '#E1B0B0']

const  sumValor = (team, arr) =>  team[arr].reduce((acc, el) => acc += el.valor, 0)

export default {
    props: {
        teams: {
            type: Array,
            default: () => []
        },
        showFecha:{
            type: Boolean,
            default: false
        },
        fecha: {
            type: Array,
            default: () => []
        },
    },

    data(){
        return {
            datos: [],
            titulo: 'Ventas en los últimos 7 dias',
            tipo: 1,
            tipoCantidad: false
        }
    },
    computed:{
        tituloModal(){
            let message = 'Ventas en los últimos 7 dias'
            switch (this.tipo){
            case 1:
                message = 'Ventas en los últimos 7 dias'
                break;
            case 2:
                message = 'Ventas en los últimos 12 meses'
                break;
            case 3:
                message = 'Tipos de clientes'
                break;
            case 4:
                message = 'Compradores por genero'
                break;
            case 5:
                message = 'Compradores por dispositivo'
                break;
            
            default:
                break;
            }

            return message
        },
        params(){
            const teams = this.teams.map(el => el.id).toString()
            const params = {
                teams,
                limited: 0
            }

            if(this.showFecha){
                params['fecha'] = this.fecha
            }

            return params
        }
    },
    methods: {
        async toggle(tipo){
            this.tipo = tipo
            this.tipoCantidad = ![1, 2].includes(this.tipo)
            await this.buscarDatos()
            this.$refs.modalVentasUltimos.toggle();
        },

        async buscarDatos(){
            switch (this.tipo){
            case 1:
            case 2:
                await this.getDataVentas()
                break;
            case 3:
                await this.getDataTipoCliente()
                break;
            case 4:
                await this.getDataGenero()
                break;
            case 5:
                await this.getDataDispositivo()
                break;
            }
        },
        
        async getDataVentas(){
            try {
               
                const { data } = this.tipo == 2 ? await comparativa.ventasUltimoAnio(this.params) : await comparativa.ventasUltimaSemana(this.params);

                this.datos = data.map((el) => {
                    const total = sumValor(el, 'rangos')
                    el.rangos = el.rangos.map((ele, index) => {
                        ele.color = colors[index]
                        ele.porcentaje = total > 0 ? ((ele.valor * 100) / total) : 0
                        ele.texto = this.formatearFecha(ele.fecha, this.tipo == 2 ? 'MMMM' : 'dddd DD')
                        return ele
                    })
                    el.total = total
                    return el
                })

            } catch (error){
                this.error_catch(error)
            }
        },
        async getDataTipoCliente(){
            try {
                const { data } = await comparativa.tipoClientes(this.params);

                this.datos = data.map(el => {
                    const total = sumValor(el, 'tipos')

                    el.rangos = el.tipos.map((ele, index) => {
                        ele.color = colors[index]
                        ele.porcentaje = total > 0 ? ((ele.valor * 100) / total) : 0
                        ele.texto = ele.tipo
                        return ele
                    })

                    el.total = total
                    return el
                    
                })
            } catch (error){
                this.error_catch(error)
            }
        },
        async getDataGenero(){
            try {
                const { data } = await comparativa.generoCantidad(this.params);

                this.datos = data.map(el => {
                    let total = el.mujer + el.hombre + el.feminista
                    el.rangos = [
                        { 
                            color: colors[0],
                            texto: 'Mujeres',
                            valor: el.mujer,
                            porcentaje: total > 0 ? (el.mujer * 100) / total:  0
                        },
                        { 
                            color: colors[1],
                            texto: 'Hombres',
                            valor: el.hombre,
                            porcentaje: total > 0 ? (el.hombre * 100) / total:  0
                        },
                        { 
                            color: colors[2],
                            texto: 'Otros',
                            valor: el.feminista,
                            porcentaje: total > 0 ? (el.feminista * 100) / total:  0
                        },
                    ]

                    el.total = total
                    return el
                })

            } catch (error){
                this.error_catch(error)
            }
        },
        async getDataDispositivo(){
            try {
                const { data } = await comparativa.dispositivoCantidad(this.params);

                this.datos = data.map(el => {
                    let total = el.desconocido + el.web + el.android + el.ios
                    el.rangos = [
                        { 
                            color: colors[0],
                            texto: 'Desconocido',
                            valor: el.desconocido,
                            porcentaje: total > 0 ? (el.desconocido * 100) / total:  0
                        },
                        { 
                            color: colors[1],
                            texto: 'Android',
                            valor: el.android,
                            porcentaje: total > 0 ? (el.android * 100) / total:  0
                        },
                        { 
                            color: colors[2],
                            texto: 'IOS',
                            valor: el.ios,
                            porcentaje: total > 0 ? (el.ios * 100) / total:  0
                        },
                        { 
                            color: colors[3],
                            texto: 'Web',
                            valor: el.web,
                            porcentaje: total > 0 ? (el.web * 100) / total:  0
                        },
                    ]


                    el.total = total
                    return el
                })

            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>
